<template>
	<div>
		<page-bar name="Edit Gallery">
			<template v-slot:actions>
				<v-btn @click="closeDialog = true" text>Close</v-btn>
			</template>
		</page-bar>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-col cols="12" md="4">
					<v-card v-bind="customCard">
						<v-toolbar class="rounded-t" flat>
							<v-toolbar-title class="d-flex align-center">
								Details
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn @click="updateGallery(editing)" color="primary">
								<v-icon>mdi-content-save</v-icon>
							</v-btn>
						</v-toolbar>
						<v-container fluid>
							<v-row dense>
								<v-col cols="12">
									<v-text-field v-model="editing.name" autocomplete="new-password" counter="200" dense
												  label="Name" outlined></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-textarea v-model="editing.description" auto-grow autocomplete="new-password"
												counter="500" dense label="Description" outlined></v-textarea>
								</v-col>

								<v-col cols="12">
									<v-row dense>
										<v-spacer></v-spacer>
										<v-col cols="auto">
											<v-switch v-model="editing.active" dense hide-details inset
													  label="Active"></v-switch>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
				<v-col cols="12" md="8">
					<gallery-images :gallery-id="editing.id"></gallery-images>
				</v-col>
			</v-row>
		</v-container>
		<card-dialog :showing="closeDialog" @cancel="closeDialog = false" @confirmed="confirmedClose"
					 confirm-text="Close" title="Confirm">
			<template v-slot:dialog-body>
				Are you sure you want to close this window?
			</template>
		</card-dialog>
	</div>
</template>

<script>
import PageBar from '@/components/global/PageBar'
import {mapActions, mapGetters} from 'vuex'
import GalleryImages from '@/components/gallery/GalleryImages'
import CardDialog from '@/components/global/CardDialog'

export default {
	name: 'GalleryEdit',
	components: {CardDialog, GalleryImages, PageBar},
	data: () => ({
		editing: {},
		closeDialog: false,
	}),
	created() {
		this.editing = {...this.gallery}
	},
	methods: {
		...mapActions('gallery/gallery', [
			'updateGallery'
		]),
		confirmedClose() {
			this.closeDialog = false
			this.$router.push({name: 'Galleries'})
		},
	},
	computed: {
		...mapGetters('gallery/gallery', [
			'gallery'
		]),
		changesMade() {
			return this.editing.name !== this.gallery.name ||
				this.editing.description !== this.gallery.description ||
				this.editing.active !== this.gallery.active
		}
	}
}
</script>

<style scoped>

</style>
