<template>
	<div>
		<v-card v-bind="customCard">
			<v-toolbar class="rounded-t" flat>
				<v-toolbar-title class="d-flex align-center">
					Images
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn :disabled="!canSave" @click="resequenceGalleryImages(images)" class="mr-2" color="primary">
					<v-icon>mdi-content-save</v-icon>
				</v-btn>
				<v-btn @click="dialogMode = 'create'" color="primary">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar>
			<v-container fluid>
				<draggable v-model="images" @end="dragEnd" class="row" handle=".handle">
					<template v-for="(img, index) in images">
						<v-col :key="index" cols="12" md="3">
							<v-hover>
								<template v-slot:default="{ hover }">
									<v-card :elevation="cardElevation" align="center" class="pa-0" height="200"
											justify="center">
										<v-img v-if="img.imageUrl" :src="img.imageUrl" height="200"
											   style="border-radius: 4px;"></v-img>
										<missing-image v-else></missing-image>
										<v-fade-transition>
											<v-overlay v-if="hover" absolute>
												<div class="d-flex fill-height"
													 style="flex-direction: column; position: absolute;">
													<div class="d-flex pl-2 pt-2">
														<v-icon class="handle">mdi-crosshairs-gps</v-icon>
														<v-spacer></v-spacer>
													</div>
												</div>
												<div class="d-flex fill-height" style="flex-direction: column;">
													<div class="d-flex fill-height align-center justify-center">
														<v-btn v-if="img.imageUrl" @click="previewUrl = img.imageUrl"
															   icon>
															<v-icon>mdi-arrow-expand</v-icon>
														</v-btn>
														<v-btn @click="editGalleryImage(img)" icon>
															<v-icon>mdi-pencil</v-icon>
														</v-btn>
														<v-btn @click="deleteId = img.id" icon>
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</div>
												</div>
											</v-overlay>
										</v-fade-transition>
									</v-card>
								</template>
							</v-hover>
						</v-col>
					</template>

				</draggable>
			</v-container>
		</v-card>

		<FileBrowser @fileSelected="setGalleryImagePath" :showing.sync="showingFileBrowser"
					 file-type="image"></FileBrowser>

		<card-dialog :showing="dialogMode !== 'hidden'"
					 :title="`${dialogMode === 'create' ? 'Create' : 'Edit'} Gallery Image`"
					 @cancel="closeGalleryImageDialog" @confirmed="confirmedGalleryImageDialog"
					 :confirm-disabled="!valid" confirm-text="Save" max-width="600px">
			<template v-slot:dialog-body>
				<v-form v-model="valid" ref="galleryImageForm">
					<v-row dense>
						<v-col cols="12">
							<v-text-field v-model="galleryImage.name"
										  :rules="[validation.required, validation.notEmpty]" autocomplete="off"
										  counter="100" dense label="Title" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="galleryImage.imagePath"
										  :rules="[validation.required, validation.notEmpty]" autocomplete="off" dense
										  label="Image Path" outlined readonly>
								<template v-slot:append-outer>
									<v-icon @click="showingFileBrowser = true">mdi-image-search</v-icon>
								</template>
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="galleryImage.description" auto-grow autocomplete="off" counter="500"
										dense label="Description" outlined></v-textarea>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="previewUrl !== null" fullscreen hide-card-actions max-width="800px"
					 title="Image Preview">
			<template v-slot:title-actions>
				<v-btn @click="previewUrl = null" text>Close</v-btn>
			</template>
			<template v-slot:dialog-body>
				<v-img :src="previewUrl" contain max-height="80vh"></v-img>
			</template>
			<template v-slot:actions>
				<v-spacer></v-spacer>
				<v-btn @click="previewUrl = null" color="primary" text>Close</v-btn>
			</template>
		</card-dialog>

		<card-dialog :showing="deleteId !== null" @cancel="deleteId = null" @confirmed="destroyGalleryImage(deleteId)"
					 confirm-color="red" confirm-text="Delete" title="Delete Gallery Image" title-color="red">
			<template v-slot:dialog-body>
				Are you sure you want to delete this gallery image? This action cannot be undone.
			</template>
		</card-dialog>
	</div>
</template>

<script>
import MissingImage from '@/components/global/MissingImage'
import draggable from 'vuedraggable'
import {mapActions, mapGetters} from 'vuex'
import CardDialog from '@/components/global/CardDialog'
import FileBrowser from '@/components/global/FileBrowser'

const defaultGalleryImage = {
	name: null,
	description: null,
	imagePath: null,
}

export default {
	name: 'GalleryImages',
	props: {
		galleryId: {type: Number, required: true},
	},
	components: {FileBrowser, CardDialog, draggable, MissingImage},
	data: () => ({
		showingFileBrowser: false,
		images: [],
		galleryImage: {...defaultGalleryImage},
		valid: false,
		canSave: false,
		dialogMode: 'hidden',
		previewUrl: null,
		deleteId: null,
	}),
	created() {
		this.listGalleryImages(this.galleryId)
	},
	methods: {
		...mapActions('gallery/galleryImage', [
			'listGalleryImages',
			'createGalleryImage',
			'resequenceGalleryImages',
			'updateGalleryImage',
			'destroyGalleryImage'
		]),
		editGalleryImage(item) {
			this.galleryImage = {...item}
			this.dialogMode = 'edit'
		},
		confirmedGalleryImageDialog() {
			if (!this.valid) return
			if (this.dialogMode === 'create') {
				this.createGalleryImage({
					galleryId: this.galleryId,
					name: this.galleryImage.name.trim(),
					description: this.galleryImage.description?.trim(),
					imagePath: this.galleryImage.imagePath
				})
			} else if (this.dialogMode === 'edit') {
				this.updateGalleryImage({
					id: this.galleryImage.id,
					name: this.galleryImage.name.trim(),
					description: this.galleryImage.description?.trim(),
					imagePath: this.galleryImage.imagePath
				})
			}
		},
		dragEnd(data) {
			if (this.canSave) return
			if (data.newIndex !== data.oldIndex) {
				this.canSave = true
			}
		},
		closeGalleryImageDialog() {
			this.$refs.galleryImageForm.reset()
			this.$refs.galleryImageForm.resetValidation()
			this.dialogMode = 'hidden'
		},
		setGalleryImagePath(data) {
			this.galleryImage.imagePath = data
			this.showingFileBrowser = false
		}
	},
	computed: {
		...mapGetters('gallery/galleryImage', [
			'galleryImages',
			'listStatus',
			'createStatus',
			'updateStatus',
			'resequenceStatus',
			'destroyStatus',
		])
	},
	watch: {
		listStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.images = [...this.galleryImages]
			}
		},
		resequenceStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.canSave = false
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.$refs.galleryImageForm.reset()
				this.$refs.galleryImageForm.resetValidation()
				this.dialogMode = 'hidden'
				this.galleryImage = {...defaultGalleryImage}
				this.listGalleryImages(this.galleryId)
			}
		},
		updateStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.$refs.galleryImageForm.reset()
				this.$refs.galleryImageForm.resetValidation()
				this.dialogMode = 'hidden'
				this.galleryImage = {...defaultGalleryImage}
				this.listGalleryImages(this.galleryId)
			}
		},
		destroyStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.deleteId = null
				this.listGalleryImages(this.galleryId)
			}
		},
	}
}
</script>

<style>
.v-overlay__content {
	height: 100%;
	width: 100%;
}


.handle {
}

.handle:hover {
	cursor: grab;
}

.handle:active {
	cursor: grabbing;
}
</style>
