<template>
	<v-dialog v-model="showing" max-width="1200" persistent @keydown.esc="$emit('update:showing', false)">
		<v-card>
			<v-toolbar dark flat short>
				<v-toolbar-title>File Browser {{ folder.name ? ' - ' + folder.fullPath : '' }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn @click="refresh" class="mr-2" color="primary">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
				<v-btn @click="$emit('update:showing', false)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-container class="px-0 mx-0" fluid>
					<v-row>
						<v-spacer></v-spacer>
						<v-col class="overflow-auto" cols="4" style="max-height: calc(90vh - 80px);">
							<file-tree :items="roots" @openChanged="folder = $event" :active-path.sync="selectedPath"
									   class="mt-2"></file-tree>
						</v-col>
						<v-col ref="imageColumn" class="overflow-auto" cols="8" style="max-height: calc(90vh - 80px);">
							<v-row v-if="folder" dense>
								<v-col v-for="file in folder.files" :key="file.name" cols="3">
									<v-hover>
										<template v-slot:default="{ hover }">
											<v-card @click="selectFile(file)" height="200">

												<template v-if="isImage(file.name)">
													<v-img :src="meta.baseUrl + file.fullPath" height="200"
														   style="border-radius: 4px;"></v-img>
													<v-fade-transition>
														<v-overlay v-if="hover" absolute>
															<div class="d-flex flex-column justify-center"
																 style="top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; position: absolute">
																<p>{{ file.name.split('.')[0] }}</p>
																<p>{{ file.name.split('.')[1].toUpperCase() }}</p>
																<p>{{ calculateSize(file.size) }}</p>
															</div>
														</v-overlay>
													</v-fade-transition>
												</template>
												<template v-else>
													<v-sheet color="secondary" height="100%" rounded width="100%">
														<div class="d-flex flex-column justify-center"
															 style="top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; position: absolute">
															<p>{{ file.name.split('.')[0] }}</p>
															<p>{{ file.name.split('.')[1].toUpperCase() }}</p>
															<p>{{ calculateSize(file.size) }}</p>
														</div>
													</v-sheet>
												</template>


											</v-card>
										</template>
									</v-hover>
								</v-col>
							</v-row>
						</v-col>
						<v-spacer></v-spacer>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import FileTree from '@/components/global/FileTree'

export default {
	name: 'FileBrowser',
	components: {FileTree},
	props: {
		showing: {type: Boolean, required: true},
		fileType: {
			validator: val => ['image', 'any'].indexOf(val) !== -1,
			default: 'any'
		},
		selectedPath: '',
		mazFileSize: {type: Number, default: 0}
	},
	data: () => ({
		folder: {
			folders: [],
			files: []
		},
	}),
	created() {
		this.getMeta()
		this.listRoots()
	},
	methods: {
		...mapActions('media/file', [
			'getMeta',
			'listRoots',
			'listDetails',
		]),
		...mapActions('global/alert', [
			'alertUser'
		]),
		async refresh() {
			this.listDetails({rootPath: this.folder.fullPath, refresh: true}).then(res => {
				this.folder.folders = res[0].folders
				this.folder.files = res[0].files
			})
		},
		selectFile(file) {
			if (this.mazFileSize > 0 && file.size > this.mazFileSize) {
				this.alertUser({
					type: 'error',
					message: `The maximum allowed file size is ${this.calculateSize(this.mazFileSize)}`
				})
			} else if (this.fileType === 'image' && !this.isImage(file.name)) {
				this.alertUser({
					type: 'error',
					message: 'An image file type is required'
				})
			} else {
				this.$emit('fileSelected', file.fullPath)
				this.$emit('update:showing', false)
			}
		},
		isImage(path) {
			return ['jpg', 'jpeg', 'png', 'tif', 'tiff', 'gif'].indexOf(path.split('.').pop()) > -1
		},
		calculateSize(size) {
			if (size <= 100000) {
				return `${(size / 1024).toFixed(2)}KB`
			}
			return `${(size / (1024 * 1024)).toFixed(2)}MB`
		},
	},
	computed: {
		...mapGetters('media/file', [
			'meta',
			'roots',
		]),
		imageColumnWidth() {
			return (this.$refs.imageColumn.clientWidth - 32) / 4
		}
	}
}
</script>

<style scoped>

</style>
